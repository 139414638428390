import React, { useEffect, useState } from "react";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

function SearchBox(props) {
  const [keyword, setKeyword] = useState("");
  // let query = useQuery();

  let history = useHistory();

  function handleSubmit(e) {
    // home page search
    history.push(`/ara?kelime=${keyword}`);

    // e.preventDefault();
  }

  function handleChange(e) {
    setKeyword(e.target.value);
  }

  useEffect(() => {
    let i = 0;

    // Placeholder Animation Start
    let inputSelector = document.querySelectorAll("input, textarea");

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("focus", function (event) {
        return this.parentElement.parentElement.classList.add("focused");
      });
    }

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("blur", function (event) {
        let inputValue = this.value;
        if (inputValue === "") {
          this.parentElement.parentElement.classList.remove("filled");
          this.parentElement.parentElement.classList.remove("focused");
        } else {
          this.parentElement.parentElement.classList.add("filled");
        }
      });
    }
  });

  return (
    <div className="section-full mb-lg-3 search-box">
      <div className="container p-0">
        <div className="find-job-bx">
          <form className="dezPlaceAni" onSubmit={handleSubmit}>
            <div className="row">
              <div
                className={
                  !keyword ? "col-xs-12 col-sm-12" : "col-xs-9 col-sm-9"
                }
              >
                <div className="form-group">
                  <label>İlan, kelime ara...</label>
                  <div className="input-group">
                    <input
                      type="text"
                      value={keyword}
                      name="kelime"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {keyword && (
                <div className="col-xs-3 col-sm-3 mb-2">
                  <button type="submit" className="site-button btn-block">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  ...advertRedux.actions,
})(SearchBox);
