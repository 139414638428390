import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LayoutV1 from "markup/Layout/LayoutV1";
import * as categoryEndpoint from "modules/Category/_redux/categoryEndpoint";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import { connect, shallowEqual, useSelector } from "react-redux";
import FilterSidebar from "./FilterSidebar";
import AdvertCard from "../Main/parts/AdvertCard";
import { Helmet } from "react-helmet";
import Skeleton from "@material-ui/lab/Skeleton";
import DrawerMenu from "./parts/filters/mobileSections/DrawerMenu";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PriceFilter from "../FilterComponents/PriceFilter";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import AdvertCardListView from "modules/Main/parts/AdvertCardListView";

let bnr = require("images/banner/bnr1.jpg");

function FilterCategory(props) {
  const myRef = useRef(null);

  const { filters } = useSelector(
    (state) => ({
      filters: state.category.categoryDetail.filters,
    }),
    shallowEqual
  );

  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);

  const [advertViewStyle, setAdvertViewStyle] = useState("grid");

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 12),
    sortBy: withDefault(StringParam, "desc"),
  });

  useEffect(() => {
    let categorySlug = props.match.params.categorySlug;

    //console.log({ selectedFilter: props.selectedFilter });
    categoryEndpoint
      .getCategoryPaginate(categorySlug, props.selectedFilter, query.page)
      .then((res) => res.data)
      .then((result) => {
        props.setAdvertsOfCategory(result.adverts);
        props.setCategory(result.category);
      });
  }, [props.selectedFilter, query.page]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      props.resetFilter();
      props.resetCategory();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function nextQuery(data) {
    myRef.current.scrollIntoView();
    setQuery((querys) => ({
      page: querys.page + 1,
    }));
  }
  function backQuery(data) {
    myRef.current.scrollIntoView();
    setQuery((querys) => ({
      page: querys.page - 1,
    }));
  }

  const { categoryDetail, adverts } = props;

  useEffect(() => {
    if (width < 768 && advertViewStyle == "list") setAdvertViewStyle("grid");
  }, [width]);

  if (!categoryDetail.id) {
    return (
      <LayoutV1>
        <div className="page-content bg-white">
          <div className="dez-bnr-inr overlay-black-middle">
            <div className="container">
              <div className="dez-bnr-inr-entry">
                <h1 className="text-white">{categoryDetail.name}</h1>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="section-full browse-job p-b50 ">
              <div className="container d-flex flex-row">
                <div className="col-xl-3 col-lg-3 col-md-5 m-b30">
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={500}
                    style={{ marginTop: 10 }}
                  />
                </div>

                <div className="col-xl-9 col-lg-9 col-md-7">
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={500}
                    style={{ marginTop: 10 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutV1>
    );
  }

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar - {categoryDetail.name}</title>
        <meta name="description" content={categoryDetail.body} />
      </Helmet>
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr"
          style={{
            backgroundColor: categoryDetail.color,
            boxShadow: "0 0 10px 0 rgb(0 0 0 / 6%)",
          }}
        >
          <div className="container">
            <div
              className="dez-bnr-inr-entry d-flex flex-column justify-content-center"
              style={{ textAlign: "left", height: "100%" }}
            >
              <div className="row">
                <div className="col-10">
                  <h1
                    className="text-black m-0 pt-1 pb-1"
                    style={{ color: "white", textAlign: "left" }}
                  >
                    {categoryDetail.name}
                  </h1>
                  <p
                    className="text-black m-0 pt-1 pb-1"
                    style={{ color: "white", lineHeight: 1.5 }}
                  >
                    {categoryDetail.body}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block" ref={myRef}>
          <div className="section-full browse-job p-b50">
            <div className="container">
              <div className="row">
                {adverts.length >= 0 && (
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="job-bx-title clearfix">
                      <div className="float-left">
                        {width > 768 && (
                          <div className="d-flex align-items-center flex-wrap">
                            {filters && <FilterSidebar filters={filters} />}

                            {filters.length > 0 && <PriceFilter />}

                            <div className="d-flex align-items-end">
                              <DrawerMenu filters={filters} width={width} />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-row-reverse float-right">
                        {width > 768 ? (
                          <div className="table-cell float-right p-tb5 p-r10 align-items-center justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                              <div>
                                <Link
                                  to={"#"}
                                  className="p-lr5"
                                  onClick={() => setAdvertViewStyle("list")}
                                >
                                  <i className="fa fa-th-list" />
                                </Link>
                                <Link
                                  to={"#"}
                                  className="p-lr5"
                                  onClick={() => setAdvertViewStyle("grid")}
                                >
                                  <i className="fa fa-th" />
                                </Link>
                              </div>
                              <p className={classes.contentStyle}>Görüntüle</p>
                            </div>
                          </div>
                        ) : null}

                        {width <= 768 && (
                          <div className="table-cell float-right p-1 d-flex flex-column align-items-center">
                            <DrawerMenu filters={filters} width={width} />
                          </div>
                        )}
                      </div>
                    </div>
                    <ul
                      className="post-job-bx browse-job-grid row mt-4"
                      style={{ padding: "0" }}
                    >
                      {adverts.map((item, index) => {
                        return advertViewStyle == "grid" ? (
                          <AdvertCard advert={item} key={index} />
                        ) : (
                          <AdvertCardListView advert={item} key={index} />
                        );
                      })}
                    </ul>

                    <div className="m-t30 mb-4">
                      <div className="d-flex col justify-content-between">
                        <div>
                          {query.page !== 1 && (
                            <button
                              disabled={query.page === 1}
                              className="site-button button-sm mr-auto"
                              onClick={() => backQuery()}
                            >
                              <i className="ti-arrow-left" /> Geri
                            </button>
                          )}
                        </div>
                        <div>
                          {(query.page - 1) * 10 <= categoryDetail.rate && (
                            <button
                              className="site-button button-sm"
                              onClick={() => nextQuery()}
                            >
                              İleri <i className="ti-arrow-right" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {adverts.length === 0 && (
                  <div className="col-12 content-inner-2 ">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="p-3">
                        <h3 className="m-b5  text-center">Son Sayfa</h3>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => props.history.push("/")}
                        >
                          Ana Sayfa
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

const useStyles = makeStyles((theme) => ({
  contentStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: "6px 16px",
    minWidth: "64px",
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    fontSize: "10px",
    margin: 0,
  },
}));

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  adverts: state.advert.advertsOfCategory.results,
  categoryDetail: state.category.categoryDetail,
  selectedFilter: state.category.selectedFilter,
});

export default connect(mapStateToProps, {
  ...categoryRedux.actions,
  ...advertRedux.actions,
})(FilterCategory);
