import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { convertPrice, convertPriceNumber } from "../../helpers/PriceHelper";
import { connect } from "react-redux";
import * as advertRedux from "../Advert/_redux/advertRedux";
import * as categoryRedux from "../Category/_redux/categoryRedux";
import { formatNumberWithCommas } from "helpers/FormatNumbersWithComma";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  dropdown: {
    position: "absolute",
    width: 550,
    // height: 200,
    top: 40,
    right: 0,
    left: -250,
    zIndex: 21,
    border: "1px solid #c3c3c3",
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    borderRadius: 4,
    background: "transparent",
    fontSize: 16,
    height: "100%",
    border: "1px solid #bababa",
    cursor: "pointer",
    color: "#333333",
    width: 40,
    height: 40,
    marginLeft: 10,
  },
  applyButton: {
    background: "rgb(17, 152, 110)",
    color: "#efefef",
    borderRadius: 4,
    border: "1px solid rgb(17, 152, 110)",
    fontSize: 15,
    height: "100%",
    cursor: "pointer",
    width: "100%",
  },
  clearPriceFilterButton: {
    position: "absolute",
    background: "transparent",
    outline: "none",
    border: 0,
    cursor: "pointer",
    height: "100%",
    fontSize: 28,
    left: -15,
  },
}));

function PriceFilter(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [prices, setPrices] = useState({
    minimumPrice: "",
    maximumPrice: "",
    displayMinimumPrice: "",
    displayMaximumPrice: "",
  });

  useEffect(() => {
    if (
      Object.keys(props.selectedFilter).some(
        (filter) => filter == "minMaxFiyat"
      )
    ) {
      setPrices({
        minimumPrice: props.selectedFilter.minMaxFiyat[0],
        maximumPrice: props.selectedFilter.minMaxFiyat[1],
      });
    } else {
      setPrices({
        minimumPrice: "",
        maximumPrice: "",
        displayMinimumPrice: "",
        displayMaximumPrice: "",
      });
    }
  }, [props.selectedFilter]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const changeHandler = (e) => {
    let rawValue = e.target.value.replace(/,/g, "");

    if (!isNaN(rawValue) || rawValue === "") {
      if (e.target.name == "minimumPrice")
        setPrices({
          ...prices,
          [e.target.name]: Number(rawValue),
          displayMinimumPrice: formatNumberWithCommas(rawValue),
        });

      if (e.target.name == "maximumPrice")
        setPrices({
          ...prices,
          [e.target.name]: Number(rawValue),
          displayMaximumPrice: formatNumberWithCommas(rawValue),
        });
    }

    //console.log(typeof prices.minimumPrice);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (Number(prices.maximumPrice) == 0 && Number(prices.minimumPrice) == 0) {
      props.removeFilter({ parent: "minMaxFiyat" });
    } else {
      props.addFilterBulk({
        parent: "minMaxFiyat",
        value: [Number(prices.minimumPrice), Number(prices.maximumPrice)],
      });
    }
  };

  const removeMinMaxPriceFilter = () => {
    props.removeFilter({ parent: "minMaxFiyat" });
    setPrices({
      minimumPrice: "",
      maximumPrice: "",
      displayMinimumPrice: "",
      displayMaximumPrice: "",
    });
  };

  return (
    <div className="mt-3 mr-1">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.root}>
          <button
            type="button"
            onClick={handleClick}
            className={classes.button}
          >
            <i className="fa fa-try" />
          </button>
          {open ? (
            <div className={classes.dropdown}>
              <div className="p-2">
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col-9">
                      <div className="row">
                        <div className="col-6">
                          <TextField
                            id="outlined-password-input"
                            label="Minimum TL"
                            name="minimumPrice"
                            value={prices.displayMinimumPrice}
                            type="text"
                            variant="outlined"
                            onChange={(e) => changeHandler(e)}
                            InputProps={{ style: { fontSize: 15 } }}
                            InputLabelProps={{
                              style: {
                                fontSize: 15,
                              },
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-password-input"
                            label="Maksimum TL"
                            name="maximumPrice"
                            value={prices.displayMaximumPrice}
                            type="text"
                            variant="outlined"
                            onChange={(e) => changeHandler(e)}
                            InputProps={{ style: { fontSize: 15 } }}
                            InputLabelProps={{
                              style: {
                                fontSize: 15,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-3 d-flex">
                      {Object.keys(props.selectedFilter).some(
                        (currentFilter) => currentFilter == "minMaxFiyat"
                      ) ? (
                        <button
                          className={classes.clearPriceFilterButton}
                          onClick={removeMinMaxPriceFilter}
                        >
                          &times;
                        </button>
                      ) : null}

                      <button className={classes.applyButton}>
                        <span>Ara</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

const mapStateToProps = (state) => ({
  adverts: state.advert.advertsOfCategory.results,
  selectedFilter: state.category.selectedFilter,
});
export default connect(mapStateToProps, {
  ...advertRedux.actions,
  ...categoryRedux.actions,
})(PriceFilter);
