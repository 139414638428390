import React, { useEffect, useState } from "react";
import AdminLayout from "markup/Layout/AdminLayout";
import { Button, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import RichTextEditor, { stateToHTML } from "react-rte";
import { convertToSlug } from "helpers";
import * as adminEndpoint from "modules/Admin/_redux/adminEndpoint";

const AdminCreateConstantPage = (props) => {
  const [form, setForm] = useState({
    title: "",
    slug: "",
    body: "",
  });

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevProps) => ({
      ...prevProps,
      [name]: value,
      slug: convertToSlug(value),
    }));
  };

  const onChangeEditor = (value) => {
    console.log(value.toString("markdown"));
    setValue(value);
    setForm((prevProps) => ({
      ...prevProps,
      body: value.toString("markdown"),
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    // ajax call
    adminEndpoint
      .createPage(form)
      .then((res) => res.data)
      .then((result) => {
        props.history.push("/admin/pages");
      });
  };

  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <div className="d-flex flex-row flex-wrap p-4">
          <div className="row">
            <div className="col-sm-12 mb-2">
              <h6>
                <Link
                  to="/admin/pages"
                  style={{
                    borderRadius: 3,
                    padding: 5,
                    borderWidth: 1,
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  ⇦ Liste
                </Link>
              </h6>
            </div>
            <div className="col-sm-12">
              <h3>Yeni Sayfa Oluştur</h3>
            </div>
            <div className="col-sm-6">
              <TextField
                name="title"
                id="standard-basic"
                className="admin-settings-text-input"
                value={form.title}
                defaultValue={form.title}
                label="Başlık"
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="slug"
                id="standard-basic"
                className="admin-settings-text-input"
                value={form.slug}
                defaultValue={form.slug}
                label="Slug"
                disabled
              />
            </div>
            <div className="col-sm-12 mt-5">
              <RichTextEditor
                value={value}
                onChange={onChangeEditor}
                className="new-post-editor"
              />
            </div>
            <div style={{ padding: "24px" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={submitForm}
              >
                Kaydet
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminCreateConstantPage;
