import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import LayoutV1 from "markup/Layout/LayoutV1";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import AdvertCard from "../Main/parts/AdvertCard";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let bnr = require("images/banner/bnr1.jpg");
function SearchList(props) {
  const { adverts } = useSelector(
    (state) => ({
      adverts: state.advert.searchKeyword.results,
    }),
    shallowEqual
  );

  const [pace, setPace] = useState(false);
  const classes = useStyles();
  let query = useQuery();

  useEffect(() => {
    fetchSearchResult(query.get("kelime"));
  }, [query.get("kelime")]);

  function fetchSearchResult(keyword) {
    setPace(true);
    advertEndpoint
      .searchAdvert(keyword)
      .then((res) => res.data)
      .then((result) => {
        props.setAdvertsOfSearch(result.hits);
        setPace(false);
        //setOptions(Object.keys(result.hits).map((key) => result.hits[key]));
      });
  }

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar - Arama Sonuçları</title>
        <meta
          name="description"
          content={`Alıcılar üzerinde yayınlanan ilanlar arasında "${query.get(
            "kelime"
          )}" kelimesi ile ilgili olan ilanlar listesi.  `}
        />
      </Helmet>
      <Backdrop className={classes.backdrop} open={pace}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="page-content">
        <div
          className="dez-bnr-inr"
          style={{
            backgroundColor: "#fbfbfb",
            boxShadow: "rgb(0 0 0 / 6%) 0px 0px 10px 0px",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-black">
                "{query.get("kelime")}" Arama Sonuçları
              </h1>
            </div>
          </div>
        </div>

        <div className="section-full bg-white content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {adverts.length > 0 && (
                  <div className="row post-job-bx browse-job">
                    {adverts.map((item, index) => (
                      <AdvertCard advert={item} key={index} />
                    ))}
                  </div>
                )}
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="p-3">
                    <h3 className="m-b5 pl-3 text-center">
                      {adverts.length === 0
                        ? "Aramanıza uygun ilan bulunamadı."
                        : adverts.length + " İlan bulundu. "}
                    </h3>
                    {/*<h6 className="fw4 m-b0">İnteraktif ilan listesi</h6>*/}
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => props.history.push("/")}
                    >
                      Ana Sayfa
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(null, {
  ...advertRedux.actions,
})(SearchList);
