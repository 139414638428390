import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "modules/Auth/_redux/authRedux";
// import { toast } from "react-toastify";
// import { info } from "modules/Auth/_redux/authCrud";
// import { GoogleLogin } from "react-google-login";
import JWTService from "@core/auth/JWTService";
import PhoneField from "./parts/PhoneField";
import {
  isValidTurkishPhoneNumber,
  phoneFlatFormat,
} from "../../helpers/PhoneFormats";
import { toast } from "react-toastify";

let bnr = require("images/background/bg6.jpg");

function SmsOtp(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    phone: "",
    phoneFlat: "",
  });
  const [code, setCode] = useState("");
  const [sent, setSent] = useState(false);

  function saveGsmNo(e) {
    e.preventDefault();

    const isValid = isValidTurkishPhoneNumber(form.phone);
    if (!isValid)
      return toast.error("Lütfen geçerli bir telefon numarası girin");

    JWTService.saveGsmNo(form.phoneFlat)
      .then((userInformation) => {
        setSent(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function signOut(e) {
    props.logout();
  }

  function verifyGsmNo(e) {
    e.preventDefault();
    // setLoading(true);
    JWTService.verifyGsmNo(code)
      .then((userInformation) => {
        props.setUser(userInformation.user);
      })
      .then((result) => {
        props.history.push(`/`);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function handlePhoneChange(event) {
    event.persist();
    setForm((prevState) => ({
      ...prevState,
      phone: event.target.value,
      phoneFlat: phoneFlatFormat(event.target.value),
    }));
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix" style={{ height: "100vh" }}>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom ">
              <div className="login-form style-2 col-lg-10">
                <div className="logo-header text-center p-tb30">
                  <Link to={"./"}>
                    <img src={require("images/alicilar-logo.png")} alt="" />
                  </Link>
                </div>
                <div className="clearfix" />
                <div className="tab-content nav p-b30 tab">
                  <div id="login" className="tab-pane active">
                    <form className=" dez-form p-b30">
                      <h5 className="form-title m-t0">Telefon Doğrulama</h5>
                      <div className="dez-separator-outer m-b5">
                        <div className="dez-separator bg-primary style-liner" />
                      </div>

                      <div className="form-group">
                        <PhoneField
                          value={form.phone}
                          onChange={(e) => handlePhoneChange(e)}
                        />
                      </div>

                      {sent && (
                        <div className="form-group">
                          <input
                            autoFocus
                            name="code"
                            required
                            className="form-control "
                            value={form.code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Doğrulama kodunu giriniz"
                            type="number"
                          />
                        </div>
                      )}

                      <div className="form-group text-left">
                        <div className="d-flex justify-content-between align-items-center">
                          {!sent && (
                            <button
                              className="site-button dz-xs-flex m-r5"
                              disabled={loading}
                              onClick={saveGsmNo}
                            >
                              {loading ? "Yükleniyor.." : "Doğrula"}
                            </button>
                          )}

                          {sent && (
                            <button
                              className="site-button dz-xs-flex m-r5"
                              disabled={loading}
                              onClick={verifyGsmNo}
                            >
                              {loading ? "Yükleniyor.." : "Doğrula"}
                            </button>
                          )}
                          <span className="custom-control custom-checkbox">
                            <button
                              className="site-button dz-xs-flex m-r5 bg-secondary"
                              onClick={signOut}
                            >
                              Çıkış
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(withRouter(SmsOtp));
