import AuthRoles from "@core/auth/AuthRoles";
import AdminDashboard from "modules/Admin/views/AdminDashboard";
import AdminUsers from "modules/Admin/views/AdminUsers";
import AdvertIndex from "modules/Admin/views/AdminAdvert/AdvertIndex";
import AdminAdvertDetail from "modules/Admin/views/AdminAdvert/AdminAdvertDetail";
import AdminProducts from "modules/Admin/views/AdminProducts";
import AdminOrders from "modules/Admin/views/AdminOrders";
import AdminCategories from "modules/Admin/views/AdminCategory/AdminCategories";
import AdminSettings from "modules/Admin/views/AdminSettings/AdminSettings";
import AdminSearch from "modules/Admin/views/AdminSearch";
import AdminPages from "modules/Admin/views/AdminPages";
import AdminCategoryDetail from "modules/Admin/views/AdminCategory/AdminCategoryDetail";
import AdminCreateConstantPage from "../views/AdminSettings/Constant Page/AdminCreateConstantPage";
import AdminCampaigns from "../views/AdminCampaigns";
import AdminUsersDetail from "../views/AdminUsersDetail";
import AdminBoughts from "../views/AdminBoughts";

const AdminPrivateRoute = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: "/admin/dashboard",
      component: AdminDashboard,
    },
    {
      path: "/admin/users/:userId",
      component: AdminUsersDetail,
    },
    {
      path: "/admin/users",
      component: AdminUsers,
    },
    {
      path: "/admin/adverts/:advertId",
      component: AdminAdvertDetail,
    },
    {
      path: "/admin/categories/:categoryId",
      component: AdminCategoryDetail,
    },
    {
      path: "/admin/categories",
      component: AdminCategories,
    },
    {
      path: "/admin/adverts",
      component: AdvertIndex,
    },
    {
      path: "/admin/products",
      component: AdminProducts,
    },
    {
      path: "/admin/orders",
      component: AdminOrders,
    },
    {
      path: "/admin/boughts",
      component: AdminBoughts,
    },
    {
      path: "/admin/settings",
      component: AdminSettings,
    },
    {
      path: "/admin/search",
      component: AdminSearch,
    },
    {
      path: "/admin/pages/create",
      component: AdminCreateConstantPage,
    },
    {
      path: "/admin/pages",
      component: AdminPages,
    },
    {
      path: "/admin/campaigns",
      component: AdminCampaigns,
    },
  ],
};

export default AdminPrivateRoute;
