import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutV1 from "markup/Layout/LayoutV1";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import Skeleton from "@material-ui/lab/Skeleton";
import { toast } from "react-toastify";
import { getAdvertDate } from "helpers";
import AdvertBuyDialog from "modules/Main/parts/AdvertBuyDialog";
import { Helmet } from "react-helmet";
import { convertPrice } from "../../../helpers/PriceHelper";

let credit = require("images/credit1.png");
let bnr = require("images/banner/bnr3.jpg");

function AdvertDetail(props) {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);

  const { advertDetail, isLogged } = useSelector(
    (state) => ({
      advertDetail: state.advert.advertDetail,
      isLogged: state.auth.isLogged,
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log(props.advert.advertDetail.id);
    let advertSlug = props.match.params.advertSlug;
    window.scrollTo(0, 0);

    props.openPace();
    advertEndpoint
      .getAdvertDetail(advertSlug)
      .then((res) => res.data)
      .then((result) => {
        props.setAdvert(result);
        props.closePace();
      });

    return () => {
      props.cleanAdvert();
    };
  }, []);

  function takeAdvert(advertId) {
    setLoading(true);
    setDialog(false);
    props.openPace();
    advertEndpoint
      .takeAdvert(advertId)
      .then((res) => res.data)
      .then((result) => {
        toast(result.message);
        props.setBalance(result.balance);
        props.setHiddenData(result.hiddenData);
        setLoading(false);
        props.closePace();
      })
      .catch((err) => {
        setLoading(false);
        props.closePace();
      });
  }

  if (!props.advert.advertDetail.title) {
    return (
      <LayoutV1>
        <div className="page-content bg-white">
          <Skeleton animation="wave" variant="rect" height={120} />
          <div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-4">
                  <Skeleton animation="wave" variant="rect" height={500} />
                </div>
                <div className="col-lg-8">
                  <Skeleton animation="wave" variant="rect" height={500} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutV1>
    );
  }

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar - {advertDetail.title}</title>
        <meta name="description" content={advertDetail.body} />
      </Helmet>
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr "
          style={{
            backgroundColor: "#fbfbfb",
            boxShadow: "rgb(0 0 0 / 6%) 0px 0px 10px 0px",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-black text-left">
                {props.advert.advertDetail.title}
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline d-flex justify-content-start">
                  <li>
                    <Link to={"/"} style={{ color: "#000" }}>
                      Anasayfa
                    </Link>
                  </li>
                  <li style={{ color: "#000" }}>
                    {advertDetail?.category?.name}
                  </li>
                  {/*   <li style={{ color: "#000" }}>
                    {" "}
                    {props.advert.advertDetail.title}
                  </li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full content-inner-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="sticky-top">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="widget bg-white p-3 widget_getintuch radius-sm">
                          <img
                            src={advertDetail.images}
                            alt=""
                            className="img-responsive"
                          />
                        </div>
                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                          <h4 className="text-black font-weight-700 p-t10 m-b15">
                            İlan Detayı
                          </h4>
                          <ul>
                            <li>
                              <i className="ti-location-pin" />
                              <strong className="font-weight-700 text-black">
                                Konum
                              </strong>
                              <span className="text-black-light">
                                {advertDetail.location?.province?.name}
                                {` ${
                                  advertDetail.location?.district?.name
                                    ? "/"
                                    : ""
                                }`}
                                {advertDetail.location?.district?.name}
                              </span>
                            </li>

                            <li>
                              <i className="ti-arrow-up" />
                              <strong className="font-weight-700 text-black">
                                Maximum Fiyat
                              </strong>{" "}
                              {convertPrice(advertDetail.priceMax)} ₺
                            </li>

                            <li>
                              <i className="ti-arrow-down" />
                              <strong className="font-weight-700 text-black">
                                Minimum Fiyat
                              </strong>{" "}
                              {convertPrice(advertDetail.priceMin)} ₺
                            </li>

                            <li>
                              <i className="ti-time" />
                              <strong className="font-weight-700 text-black">
                                Yayınlanma Tarihi
                              </strong>
                              {getAdvertDate(
                                props.advert.advertDetail.createdAt
                              )}
                            </li>

                            <li>
                              <i className="">
                                <img src={credit} width="20" alt="" />
                              </i>
                              <strong className="font-weight-700 text-black">
                                Gerekli Bakiye
                              </strong>{" "}
                              {props.advert.advertDetail.acoin}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="job-info-box">
                    <h3 className="m-t0 m-b10 font-weight-700 title-head">
                      Açıklama
                    </h3>
                    {/*<ul className="job-info">
                      <li>
                        <strong>Yayınlanma : </strong>
                      </li>
                    </ul>*/}
                    <p className="p-t20">{props.advert.advertDetail.body}</p>

                    {props.advert.advertDetail.user.name ? (
                      <div
                        style={{
                          backgroundColor: "#ddfae3",
                          borderRadius: 10,
                          padding: 10,
                          marginBottom: 50,
                        }}
                      >
                        <h3>İlan Sahibi</h3>
                        <b>İsim</b> : {props.advert.advertDetail.user.name}{" "}
                        {props.advert.advertDetail.user.surname}
                        <br />
                        {props.advert.advertDetail.showPhoneNumber ? (
                          <>
                            <b>Telefon</b> :{" "}
                            {props.advert.advertDetail.privacy
                              ? props.advert.advertDetail.privacy[0].phone
                              : ""}
                            <br />
                          </>
                        ) : null}
                        <b>E-Posta </b>
                        {props.advert.advertDetail.user.email}
                        {props.advert.advertDetail.allowMessages ? (
                          <div className="mt-3">
                            <Link
                              to={`/messages/${props.advert.advertDetail.user.id}?advert=${props.advert.advertDetail.slug}`}
                              className="bg-primary p-2 rounded text-white send-message-button outline-none border-0 cursor-pointer"
                            >
                              Mesaj Gönder
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <h5 className="font-weight-600">Detay</h5>
                        <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                        <p>
                          İlan sahibi ile iletişime geçmeniz için gizli
                          bilgilerine ihtiyaç duyabilirsiniz, bunun için
                          "bağlantı kur" butonunu kullanabilirsiniz.
                        </p>
                        <h5 className="font-weight-600">Detaylı Bilgi</h5>

                        <AdvertBuyDialog
                          open={dialog}
                          closeDialog={() => setDialog(false)}
                          advertDetail={advertDetail}
                          takeAdvert={() =>
                            takeAdvert(props.advert.advertDetail.id)
                          }
                        />
                        {isLogged ? (
                          <div className="d-flex justify-content-between">
                            <button
                              onClick={() => setDialog(true)}
                              className="site-button mb-5"
                              disabled={loading}
                            >
                              {loading
                                ? "Lütfen bekleyiniz..."
                                : "Bağlantı Kur"}
                            </button>
                            {props.auth.user.role.includes("admin") && (
                              <Link
                                to={`/admin/adverts/${props.advert.advertDetail.id}`}
                              >
                                <button className="site-button mb-5">
                                  İlanı Düzenle
                                </button>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <Link
                            to="/login"
                            className="site-button mb-5"
                            disabled={loading}
                          >
                            Oturum Aç
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(advertRedux.reducer, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdvertDetail);
