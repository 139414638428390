import axios from "axios";

export const DASHBOARD = "v1/admin/dashboard";
export const USERS = "v1/admin/users";
export const ADVERTS = "v1/admin/adverts";
export const PRODUCTS = "v1/admin/products";
export const ORDERS = "v1/admin/orders";
export const BOUGHTS = "v1/admin/boughts";
export const CATEGORIES = "v1/admin/categories";
export const PAGE = "v1/admin/pages";
export const CAMPAIGN = "v1/admin/campaigns";

/**
 * DASHBOARD
 */
export function getDashboard() {
  return axios.get(`${DASHBOARD}`);
}

/**
 * USERS
 * @param {number} page
 * @param {number} pageSize
 * @param {string} order
 */
export function getUsers(page, pageSize, order) {
  return axios.get(
    `${USERS}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`
  );
}

export function getUser(userId) {
  return axios.get(`${USERS}/${userId}`);
}

/**
 * ADVERTS
 * @param {number} page
 * @param {number} pageSize
 * @param {string} order
 */
export function getAdverts(page, pageSize, order) {
  return axios.get(
    `${ADVERTS}?page=${
      page + 1
    }&limit=${pageSize}&sortBy=createdAt:${order}&relation=category`
  );
}

export function getAdvert(advertId) {
  return axios.get(`${ADVERTS}/${advertId}`);
}

export function updateAdvert(advertId, editedAdvert) {
  return axios.post(`${ADVERTS}/${advertId}`, { editedAdvert });
}

export function uploadAdvertImageFromAdmin(advertId, form) {
  return axios.post(`${ADVERTS}/${advertId}/upload`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * PRODUCTS
 * @param {number} page
 * @param {number} pageSize
 * @param {string} order
 */
export function getProducts(page, pageSize, order) {
  return axios.get(
    `${PRODUCTS}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`
  );
}

export function getOrders(page, pageSize, order) {
  return axios.get(
    `${ORDERS}?page=${
      page + 1
    }&limit=${pageSize}&sortBy=createdAt:${order}&relation=user`
  );
}

export function getBoughts(page, pageSize, order) {
  return axios.get(
    `${BOUGHTS}?page=${
      page + 1
    }&limit=${pageSize}&sortBy=createdAt:${order}&relation=user,advert`
  );
}

export function getCategories() {
  return axios.get(`${CATEGORIES}`);
}

export function createCategory(form) {
  return axios.post(`${CATEGORIES}`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteCategory(categoryId) {
  return axios.delete(`${CATEGORIES}/${categoryId}`);
}

// PAGES
export function getPages(page, pageSize, order) {
  return axios.get(
    `${PAGE}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`
  );
}

export function createPage(form) {
  return axios.post(`${PAGE}`, form);
}

export function deletePage(pageId) {
  return axios.delete(`${PAGE}/${pageId}`);
}

// CATEGORY
export function getCategoryDetail(categoryId) {
  return axios.get(`${CATEGORIES}/${categoryId}`);
}

export function postCategoryDetail(categoryId, editedCategory) {
  return axios.post(`${CATEGORIES}/${categoryId}`, { editedCategory });
}

// Campaigns
export function getCampaigns(page, pageSize, order) {
  return axios.get(
    `${CAMPAIGN}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`
  );
}

export function createCampaign(form) {
  return axios.post(`${CAMPAIGN}`, form);
}

export function deleteCampaign(campaignId) {
  return axios.delete(`${CAMPAIGN}/${campaignId}`);
}
