import React, { useEffect } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const SelectSection = (props) => {
  const { selectedFilter } = useSelector(
    (state) => ({
      selectedFilter: state.category.selectedFilter,
    }),
    shallowEqual
  );

  const handleFilterChange = (value, parent) => {
    if (!value) {
      props.removeFilter({ parent });
    } else {
      props.addSingleFilter({ parent, value });
    }
  };

  return (
    <aside
      id={props.filter.id}
      key={Math.random()}
      className="sidebar-filter mt-3 mr-1"
    >
      <div id="companies" className="acod-body collapse show">
        <div className="acod-content m-0">
          <Autocomplete
            onChange={(e, selecteds) =>
              handleFilterChange(selecteds, props.filter.slug)
            }
            limitTags={1}
            id="single-limited-tag"
            options={props.filter.values}
            defaultValue={
              // We need to set array first element for single select.
              selectedFilter[props.filter.slug] &&
              selectedFilter[props.filter.slug][0]
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={props.filter.title}
                size="small"
              />
            )}
            style={{ minWidth: "160px" }}
          />
        </div>
      </div>
    </aside>
  );
};

export default connect(null, {
  ...categoryRedux.actions,
  ...advertRedux.actions,
})(SelectSection);
