import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";

const RadioSection = (props) => {
  const handleFilterChange = (selecteds, parentFilter, value) => {
    if (selecteds.target.checked) {
      props.addFilter({ parent: parentFilter, value });
    } else {
      props.removeFilter({ parent: parentFilter, value });
    }
  };

  return (
    <aside
      id={props.filter.slug}
      key={props.filter.slug}
      className=" sidebar-filter bg-white mt-3 mr-1"
    >
      <div className="acod-head">
        <h6 className="acod-title">
          <div className="sidebar-filter-title">{props.filter.title}</div>
        </h6>
      </div>

      <div id="companies" className="acod-body collapse show">
        <div className="acod-content">
          {props.filter.values.map((val, index) => (
            <div key={index} className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                id={val}
                onChange={(e) => handleFilterChange(e, props.filter.slug, val)}
                type="checkbox"
                //name="checkbox-companies"
              />
              <label className="custom-control-label" htmlFor={val}>
                {val}
              </label>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default connect(null, {
  ...categoryRedux.actions,
  ...advertRedux.actions,
})(RadioSection);
